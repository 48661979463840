#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login-form {
  margin: 22px;
}
.login-form #account,
.login-form #password {
  height: 36px;
  background: #f8f8f8;
}
.login-form .ant-checkbox-wrapper {
  margin-bottom: 15px !important;
}
.login-form .login-title {
  text-align: center;
  font-size: 20px;
  color: #57ba8d;
  font-weight: bold;
  margin-bottom: 15px;
}
.login-form-button {
  font-size: 20px;
  width: 100%;
  height: 40px;
  background: #57ba8d;
}
.login-form-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: #0591d9;
  font-weight: 600;
}
.logined-title {
  font-size: 20px;
  color: #0591d9;
  font-weight: 600;
  text-align: center;
  margin: 50px 0px;
  padding-top: 40px;
}
.user-border {
  width: 120px;
  height: 120px;
  border: 1px solid #93cef7;
  margin: auto;
}
.user-name {
  width: 120px;
  margin: auto;
  text-align: center;
  margin-top: 5px;
}
.logined-button {
  width: 200px;
  margin: 50px auto ;
}
.button-login {
  height: 35px !important;
  font-size: 14px !important;
  width: 200px;
}
.ant-form-item {
  margin-bottom: 40px;
}
.ant-form-item:nth-child(3) {
  margin-bottom: 22px;
}
.ant-checkbox-wrapper {
  margin-bottom: 36px;
}

.login {
  width: 325px;
  margin-left: 60% ;
  background: #f6f6f6;
  position: absolute;
  z-index: 3;
  top: 20%;
  box-shadow: -2px -2px 21px rgba(102, 102, 102, 0.5), 2px 0px 5px rgba(102, 102, 102, 0.5);
}
.content {
  background: url(../../static/media/background.d602a07d.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.fish_login {
  position: absolute;
  left: 8%;
  top: 25%;
}
.foot {
  text-align: center;
  color: #7b7b7b;
  position: absolute;
  bottom: 0px;
  padding: 25px 50px;
  background-color: #f8f8f8;
  width: 100%;
}
.foot p {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background: #f8f8f8;
}
.header .head-title {
  height: 70px;
  line-height: 70px;
  padding-left: 5%;
  color: #57ba8d;
  font-weight: bold;
}

.title {
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 6px;
}
.tips {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 6px;
}
.tips {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.adminList .ant-pagination {
  text-align: right;
  margin-top: 20px;
}

.title {
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 6px;
}
.tips {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 6px;
}
.tips {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.headerinfo {
  height: 90px;
  line-height: 90px;
  background: url(../../static/media/background.d602a07d.png);
  background-size: cover;
  color: #fff;
  clear: both;
}
.headerinfo .left {
  float: left;
  margin-left: 20px;
}
.headerinfo .left .big {
  font-size: 32px;
  margin-right: 30px;
}
.headerinfo .left .small {
  font-size: 22px;
}
.headerinfo .right {
  float: right;
  margin-right: 20px;
  height: 90px;
  line-height: 90px;
}
.headerinfo .right img {
  border-radius: 100px;
  height: 24px;
  width: 24px;
}
.headerinfo .right .anticon {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

#root,
.clearfix::after {
  content: '';
  clear: both;
  zoom: 1;
  display: block;
  height: 0;
}
.layout {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.sider {
  width: 162px;
  min-width: 162px;
  background-color: #eaf6ff;
  margin-right: 30px;
}
.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-right: 30px;
}
.footer {
  text-align: center;
  height: 90px;
  line-height: 90px;
  margin-bottom: 0;
  margin-top: auto;
}
.spin_top {
  margin-top: 100px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
.thridnav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 45px;
  margin-bottom: 20px;
  background-color: #F1F8FF;
  line-height: 45px;
}
.thridnav span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 36px;
  font-size: 17px;
}
.thridnav span a {
  color: #777777;
}
.thridnav span a.active {
  color: #52A5F1;
  background-color: #F1F8FF;
}
.thridnav .slot {
  display: inline-block;
  margin-top: -12px;
  width: 0px;
  height: 0px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}
.card {
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  margin-bottom: 30px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

